import React, { FC } from 'react';
import ProductImageTitleBrand from '~/shared/product-image-title-brand/product-image-title-brand.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import { IDraftWithErrors } from '../credit-drafts.component';
import styles from './styled.module.scss';
import Text from '~/shared/text/text.component';
import { Link } from '~/shared/link';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Price from '~/shared/price/price.component';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg/svg.component';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { DEPOSIT_ONLY_LOCATION } from '~/shared/create-credit/item/item.component';

interface IProps {
    drafts: IDraftWithErrors[];
    onDelete: (itemId: string, stimeStamp: number) => void;
}

const DraftsList: FC<IProps> = ({ drafts, onDelete }) => {
    const translate = useTranslations();
    const { activeProfile } = useActiveUser();

    return (
        <div>
            {drafts?.map((draft, i) => {
                const {
                    itemNo,
                    image,
                    title,
                    brand,
                    quantity,
                    itemUrl,
                    invoiceId,
                    invoiceUrl,
                    responseError,
                    timeStamp,
                    duplicateWarning,
                    creditLocation: actualCreditLocation,
                    price,
                    deposit,
                    depositOnly,
                } = draft;

                const creditLocation = depositOnly ? DEPOSIT_ONLY_LOCATION : actualCreditLocation;

                return (
                    <div key={`${i}-${itemNo}`} className={responseError || duplicateWarning ? styles.errorDraft : styles.draft}>
                        <div className={styles.grid}>
                            <ProductImageTitleBrand className={styles.titleBrandCell} image={image} name={title} brand={brand} />
                            <ValueWithCaption caption={translate('product.itemNo', 'Vare nr.')}>
                                <Link href={itemUrl}>
                                    <Text title={itemNo} textStyle="monoSmall" overflowEllipsis>
                                        {itemNo}
                                    </Text>
                                </Link>
                            </ValueWithCaption>
                            <ValueWithCaption caption={translate('overview.invoiceNumber', 'Faktura nr.')}>
                                <Link href={invoiceUrl}>
                                    <Text textStyle="monoSmall">{invoiceId}</Text>
                                </Link>
                            </ValueWithCaption>
                            <ValueWithCaption className={styles.quantityCell} caption={translate('product.quantity', 'antal')}>
                                <Text textStyle="monoSmall">
                                    {quantity} {translate('product.piece', 'stk')}
                                </Text>
                            </ValueWithCaption>
                            <ValueWithCaption className={styles.quantityCell} caption={translate('overview.location', 'Lokation')}>
                                <Text textStyle="monoSmall">{creditLocation || '-'}</Text>
                            </ValueWithCaption>

                            <div className={styles.reasonCell}>
                                {draft?.depositOnly && <Text textStyle="monoSmall">{translate('overview.deposit', 'Depositum')}</Text>}
                            </div>

                            <div className={styles.deletePriceCell}>
                                <Button
                                    buttonSize="small"
                                    aria-label="delete"
                                    buttonStyle="clean"
                                    onClick={() => onDelete(itemNo as string, timeStamp)}
                                    icon={<Svg thick name="bin" />}
                                />
                                <div className={styles.priceCell}>
                                    {price && !draft?.depositOnly && !activeProfile?.hideNetPrice && (
                                        <Price>
                                            {price} / {translate('product.piece', 'stk')}
                                        </Price>
                                    )}
                                    {deposit && !activeProfile?.hideNetPrice ? (
                                        <Price size="medium">
                                            {translate('overview.deposit', 'Depositum')} {deposit} / {translate('product.piece', 'stk')}
                                        </Price>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        {(responseError || duplicateWarning) && (
                            <div className={styles.errorBox}>
                                <Svg name="icon-badge-warning" thick className={styles.errorIcon} />
                                {duplicateWarning && (
                                    <Text textStyle="bodySmall">
                                        {translate(
                                            'overview.savedCredits.duplicateWarning',
                                            'Dette produkt er gemt mere end én gang her i listen, slet de ikke relevante',
                                        )}
                                    </Text>
                                )}
                                {responseError && <Text textStyle="bodySmall">{responseError}</Text>}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default DraftsList;
