import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SWRInfiniteResponse, SWRInfiniteConfiguration, useSWRInfinite } from 'swr';
import useUser from '~/libs/use-user';
import { formatDateISO } from '~/helpers/format-date.helper';
import { DEFAULT_PAGING_SIZE } from '~/constants/settings';
import useDebounce from '~/shared/hooks/debounce.hook';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { CLIENT_QUERY_OPTIONS } from '~/constants/query';
import { apiClient } from '~/services/api-client';
import { useCurrentBasket } from '~/libs/queries/basket';

export type DateSetter = (date: string, type: CLIENT_QUERY_OPTIONS.fromDate | CLIENT_QUERY_OPTIONS.toDate) => void;

interface IProps {
    shouldFetch?: boolean;
    pathName: string;
    key: string;
    initialFromDate?: string;
    initialToDate?: string;
    paginationLimit?: number;
    initialSearchQuery?: string;
    localeOption?: LocaleOptions;
    updateQuery?: boolean;
    swrConfig?: SWRInfiniteConfiguration;
    query?: {
        [key: string]: any;
    };
}

const useInfiniteLoading = ({
    shouldFetch = true,
    pathName,
    key,
    initialFromDate,
    initialToDate,
    paginationLimit,
    localeOption = LocaleOptions.omit,
    swrConfig,
    updateQuery = true,
    query,
}: IProps) => {
    const { createUrl } = useEndpoints();
    const { data: basket } = useCurrentBasket();
    const { query: routerQuery, replace } = useRouter();
    const { isLoggedIn, user } = useUser();
    const { activeProfile } = useActiveUser();

    const [fromDate, setFromDate] = useState(formatDateISO(initialFromDate || new Date().toString(), -15));
    const [toDate, setToDate] = useState(formatDateISO(initialToDate || new Date().toString()));

    const [sortBy, setSortBy] = useState<string | null>(null);

    // input values
    const [searchVal, setSearchVal] = useState((routerQuery?.inPageSearch as string) || '');
    // debounced values
    const [debouncedSearch, setDebouncedSearch] = useState('');

    const debouncedSearchValue = useDebounce(searchVal, 300);

    const initialPageSize = Number(routerQuery?.page) || 1;

    const endpoint = createUrl({
        localeOption,
        endpoint: pathName,
        query: {
            ...query,
            fromDate,
            toDate,
            sortBy: sortBy as string,
            limit: paginationLimit || DEFAULT_PAGING_SIZE,
            query: debouncedSearch,
        },
    });

    useEffect(() => {
        if (debouncedSearchValue !== debouncedSearch) {
            setDebouncedSearch(debouncedSearchValue);
            setQueryProps(CLIENT_QUERY_OPTIONS.inPageSearch, debouncedSearchValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue]);

    useEffect(() => {
        const queryFromDate = routerQuery?.fromDate as string;
        const queryToDate = routerQuery?.toDate as string;
        const querySearch = routerQuery?.inPageSearch as string;
        const querySortBy = routerQuery?.sortBy as string;

        if (queryFromDate && fromDate !== queryFromDate) {
            setFromDate(queryFromDate);
        }
        if (queryToDate && toDate !== queryToDate) {
            setToDate(queryToDate);
        }

        if (querySortBy && sortBy !== querySortBy) {
            setSortBy(querySortBy);
        }

        if (querySearch && debouncedSearch !== querySearch) {
            setSearchVal(querySearch);
        }
        if (initialPageSize && size !== initialPageSize) {
            setSize(initialPageSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialPageSize, routerQuery]);

    // https://swr.vercel.app/examples/infinite-loading
    const getKey = (pageIndex: number, previousPageData: any) => {
        if (previousPageData && !previousPageData[key]) return null;
        if (pageIndex === 0) return endpoint;
        return `${endpoint}&afterCursor=${previousPageData.afterCursor}`;
    };

    const setQueryProps = (queryKey: CLIENT_QUERY_OPTIONS, value: string) => {
        const routerParams = { ...routerQuery };
        delete routerParams.slug;

        if (queryKey === CLIENT_QUERY_OPTIONS.fromDate || queryKey === CLIENT_QUERY_OPTIONS.toDate) {
            delete routerParams.page;
            delete routerParams.inPageSearch;
        }

        if (queryKey === CLIENT_QUERY_OPTIONS.inPageSearch) {
            delete routerParams.page;
            delete routerParams.fromDate;
            delete routerParams.toDate;
        }

        if (value) {
            routerParams[queryKey] = value;
        } else {
            delete routerParams[queryKey];
        }

        const path = createUrl({
            endpoint: window.location.pathname,
            localeOption: LocaleOptions.omit,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            query: {
                ...routerParams,
            },
        });

        if (updateQuery) {
            replace(path, undefined, { shallow: true });
        }
    };

    const loadMore = () => {
        setQueryProps(CLIENT_QUERY_OPTIONS.page, (size + 1).toString());
        setSize(size + 1);
    };

    const setDate: DateSetter = (date, type) => {
        setSearchVal('');
        if (type === CLIENT_QUERY_OPTIONS.fromDate) {
            setQueryProps(CLIENT_QUERY_OPTIONS.fromDate, date);
            setFromDate(date);
        }
        if (type === CLIENT_QUERY_OPTIONS.toDate) {
            setQueryProps(CLIENT_QUERY_OPTIONS.toDate, date);
            setToDate(date);
        }
    };

    const setSortByVal = (val: string) => {
        setQueryProps(CLIENT_QUERY_OPTIONS.sortBy, val);
    };

    const {
        data: responseData,
        error,
        size,
        setSize,
        mutate,
    }: SWRInfiniteResponse<any, Error> = useSWRInfinite(
        (pageIndex, previousPageData) => (shouldFetch && isLoggedIn ? [getKey(pageIndex, previousPageData), activeProfile?.id, basket?.id] : null),
        (url: string) => apiClient.auth(user).get(url),
        {
            initialSize: initialPageSize,
            revalidateOnFocus: false,
            dedupingInterval: 2000, // 2 sec
            ...swrConfig,
        },
    );

    const isLoadingInitialData = !responseData && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && responseData && typeof responseData[size - 1] === 'undefined');

    const data = responseData?.map((chunk) => chunk[key]).reduce((a, b) => a.concat(b), []) || [];

    const total = (responseData && responseData[0]?.total) || 0;
    const loaded = data.length || 0;
    const { afterCursor } = (responseData && responseData[responseData.length - 1]) || 0;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        total,
        loaded,
        afterCursor,
        error,
        loadMore,
        fromDate,
        toDate,
        setDate,
        searchVal,
        setSearchVal,
        sortBy,
        setSortByVal,
        mutate,
    };
};

export default useInfiniteLoading;
